/* @tailwind base;
@tailwind components;
@tailwind utilities; */

.menu-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    font-family: Arial, sans-serif;
    position: sticky;
}

.menu-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 10px;
    transition: all 0.3s ease;
}

.menu-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #d3d3d3;
}

.menu-icon .icon {
    color: #555;
    font-size: 20px;
}

.menu-text {
    font-size: 16px;
    color: #555;
}

.menu-item:hover .menu-icon {
    background-color: #546D9A !important;
}



.menu-item:hover .icon {
    color: #fff !important;
}

.menu-item:hover .menu-text {
    color: #546D9A;
}